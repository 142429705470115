<template>
  <div id="app">
    <!-- 通知记录 -->
    <el-row>
      <el-col :span="6" class="rows" style="width:300px">
        <el-input v-model="name" placeholder="请输入姓名"></el-input>
      </el-col>
      <el-col :span="6" class="rows">
        <el-button type="primary" style="margin-left:5px" @click="queryInfo">查询</el-button>
      </el-col>
      <el-col :span="6" class="rows" style="float:right;width:10%;margin:10px 0">
        <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
          <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title">
          </el-option>
        </el-select>
      </el-col>
    </el-row>

    <el-tabs tab-position="right">
      <el-table :data="notifyHistoryValueList" style="width: 100%">
        <el-table-column  v-if="tableTitle.includes('通知人员姓名')" prop="name" label="通知人员姓名" align="center">
        </el-table-column>
        <el-table-column  v-if="tableTitle.includes('通知人员手机号')" prop="phone" label="通知人员手机号" align="center">
        </el-table-column>
        <el-table-column  v-if="tableTitle.includes('消息内容')" prop="text" label="消息内容" align="center">
        </el-table-column>
        <el-table-column  v-if="tableTitle.includes('通知方式')" label="通知方式" align="center">
          <template slot-scope="scope">
            <el-tag style="margin-right:5px" size="mini" v-if="scope.row.sendWechat === 1">微信</el-tag>
            <el-tag style="margin-right:5px" size="mini" v-if="scope.row.sendPhone === 1">电话</el-tag>
            <el-tag style="margin-right:5px" size="mini" v-if="scope.row.sendMessage === 1">短信</el-tag>
          </template>
        </el-table-column>
        <el-table-column  v-if="tableTitle.includes('通知时间')" prop="createTime" label="通知时间" align="center">
        </el-table-column>
      </el-table>
    </el-tabs>
    <div>
      <el-pagination :current-page.sync="current" :page-size="limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="pageNotifyHistoryValue" class="pagination"></el-pagination>
    </div>
  </div>
</template>

<script>
import deviceInfo from '@/api/facilitiesManagement/DeviceInfo'
export default {
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '通知人员姓名',
      }, {
        id: 1,
        title: '通知人员手机号',
      }, {
        id: 2,
        title: '消息内容',
      }, {
        id: 3,
        title: '通知方式',
      }, {
        id: 4,
        title: '通知时间',
      }],
      tableTitle: ['通知人员姓名', '通知人员手机号', '消息内容', '通知方式', '通知时间'],

      current: 1,
      limit: 10,
      total: 1,
      dialogFormVisible1: false,
      // 当前定位页码
      currentPage: 1,
      checkEquipmentDetails: {},

      //根据姓名条件查询
      name: "",
      //通知记录
      notifyHistoryValueList: [],
    };
  },
  created() {
    this.pageNotifyHistoryValue();
  },
  methods: {
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('通知人员姓名')
      } else {
        this.tableTitle = value
      }
    },
    // 分页
    pageNotifyHistoryValue() {
      deviceInfo.pageNotifyHistoryValue(this.current, this.limit, this.name).then((res) => {
        console.log(res);
        if (res.data.message == "无通知记录") {
          this.notifyHistoryValueList = [];
        } else {
          this.total = res.data.data.total;
          this.notifyHistoryValueList = res.data.data.list;
        }

      })
    },

    //根据姓名条件查询
    queryInfo() {
      this.pageNotifyHistoryValue();
    }
  },
};
</script>

<style lang="scss" scoped>
.rows {
  margin: 10px 10px 10px 0;
}
</style>